import { unref as _unref, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ae58929"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200 warp" }
const _hoisted_2 = { class: "show-table-container" }
const _hoisted_3 = {
  key: 0,
  class: "pagination"
}

import ShowNewOffer from "../TableData/showNewOffer.vue"; // 引入
import SearchGoods from "../SearchGoods"; // 搜索
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";

import navViewsVue from "./navViews.vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { usequote } from "@/pinia/api/useQuote.js"; // pinia  最新报价
import { useClassIfy } from "@/pinia/api/classIfy"; // pinia 分类
import { storeToRefs } from "pinia";
import { ElMessage } from "element-plus";
import { useBreadcrumb } from "@/pinia/otherStore/useBreadcrumb.js"; // 面包屑


export default {
  setup(__props) {

const breadcrumbStore = useBreadcrumb(); // 面包屑
const storeClassIfy = useClassIfy(); //分类的 pinias
storeClassIfy.initClassIfy(); // 初始化 分类列表
const { nameArr } = storeToRefs(storeClassIfy);
const store = usequote(); // pinia 最新报价
const currentPage1 = ref(1); // 当前页
const route = useRoute();
const router = useRouter();
const searchWords = ref("");
const action = ref("all"); //操作数据的行为为all
const searchGoods = ref(null); // 搜索框的节点
const search_words = ref(""); // 最顶部搜索框或者是热词的内容
breadcrumbStore.init(route);
onMounted(() => {
  searchGoods.value.setKeyWords(store.params.info);
});

// 清空 store中 热词的 搜索关键字
const resethot = () => {
  store.clearHotwords();
};

// 热词如果存在或者更换的时候 把page设置为1
watch(() => store.hotwords, () => {
  currentPage1.value = 1
})


// 城市改变的时候
const changCity = (id) => {
  resethot(); //清空热词搜索关键字
  store.params.regionId = id;
  searchHnadler();
};

const changeQy = () => {
  resethot(); //清空热词搜索关键字

  store.params.regionId = "";

  searchHnadler();
};

// 模糊搜索的方法 封装
const searchHnadler = async (flag) => {
  if (!flag) {
    action.value = "search";
    store.searchQuoteList({ page: 1 });
    currentPage1.value = 1;
    console.log(store.params);
    breadcrumbStore.setMatched(
      store.params.name ? store.params.name : "最新报价"
    ); //  // 设置面包屑 只有点击拼命的时候会触发
    return;
  }
  /* 如果路由跳转过来并且携带了 parmas 参数的话 那么进行一个 info 查询 */
  if (store.info) {
    store.searchInfo(1);
  } else {
    action.value = "search";
    store.searchQuoteList({ page: 1 });
    currentPage1.value = 1;
    breadcrumbStore.setMatched(
      store.params.name ? store.params.name : "最新报价"
    ); //  // 设置面包屑 只有点击拼命的时候会触发
  }
};

// 点击品名的时候触发
const clickHandler = (row) => {
  resethot(); //清空热词搜索关键字

  storeClassIfy.params = {
    name: row.type_title,
  };
  store.params.name = row.type_title;
  store.params.spec = "";
  store.params.texture = "";
  PsetAll();
  storeClassIfy.searchClassify();
  // 如果当前在搜索页 那么就直接搜索，不在的话就跳转到搜索页
  if (route.path.includes("search")) {
    searchHnadler();
  } else {
    router.push({ name: "search-offer" });
  }
};

// 点击规格触发
const clickOthrer1 = (row) => {
  resethot(); //清空热词搜索关键字

  storeClassIfy.params.spec = row;
  storeClassIfy.searchClassify();
  store.params.spec = row;
  // 如果当前在搜索页 那么就直接搜索，不在的话就跳转到搜索页
  if (route.path.includes("search")) {
    searchHnadler();
  } else {
    router.push({ name: "search-offer" });
  }
};

// 点击参数触发
const clickOthrer2 = (row) => {
  resethot(); //清空热词搜索关键字

  storeClassIfy.params.texture = row;
  storeClassIfy.searchClassify();
  store.params.texture = row;
  // 如果当前在搜索页 那么就直接搜索，不在的话就跳转到搜索页
  if (route.path.includes("search")) {
    searchHnadler();
  } else {
    router.push({ name: "search-offer" });
  }
};

// 选择主分类的时候 进行查询
const changeType = (id) => {
  resethot(); //清空热词搜索关键字

  store.type = id;
  store.params.name = ""; // 清空品名
  store.params.spec = ""; // 清空 材质
  store.params.texture = ""; // 清空规格
  store.params.user_id = ""; // 清空搜索人
  searchHnadler(); // 调用方法直接执行进行搜索
  breadcrumbStore.setMatched("最新报价"); //  // 设置面包屑 只有点击拼命的时候会触发
};

//点击全部的时候触发
const clickAll = (flag) => {
  resethot(); //清空热词搜索关键字

  switch (flag) {
    case "品名":
      storeClassIfy.params = {};
      storeClassIfy.curTbasId = "all";
      store.params.name = "";
      store.params.spec = "";
      store.params.texture = "";
      searchHnadler();
      searchWords.value = "";
      PsetAll();
      storeClassIfy.getNameArr();
      break;
    case "规格":
      storeClassIfy.params.spec = "";
      store.params.spec = "";
      searchHnadler();
      storeClassIfy.searchClassify();
      break;
    case "材质":
      store.params.texture = "";
      storeClassIfy.params.texture = "";
      searchHnadler();
      storeClassIfy.searchClassify();
      break;
  }
  breadcrumbStore.setMatched("最新报价"); // 设置面包屑
};

//当路由改变的时候
watch(
  route,
  async (newval) => {
    console.log(newval.params, "@@@@@");
    if (route.path.includes("offer-search")) {
      if (newval.params.hotwords) {
        store.hotwords = newval.params.hotwords;
        return;
      }
      if (store.info) {
        searchHnadler(true);
      } else {
        searchHnadler();
      }
    }
    if (route.path.includes("/new-offer/all")) {
      store.initQuoteList({ page: 1 });
    }
    currentPage1.value = 1;
  },
  { immediate: true }
);

//搜索被触发的时候
const searchHandle = (words) => {
  resethot(); //清空热词搜索关键字

  console.log("routesView", words.value);
};

const isShowNav = ref(true);
//当前路由离开的时候
onBeforeRouteLeave(() => {
  resethot(); //清空热词搜索关键字
  if (route.path.includes("search")) {
    store.$reset(); // 重置pinia的参数
    breadcrumbStore.setMatched("最新报价"); // 设置面包屑
  }
});

// 分类
const searchList = [
  {
    title: "品名",
    children: nameArr,
  },
];

// 当前页改变时触发
const handleCurrentChange = async (val) => {
  if (store.hotwords) {
    store.searchToHot({ page: val });
    return;
  }
  if (action.value != "all") {
    if (store.info != "") {
      store.searchInfo(val);
    } else {
      store.searchQuoteList({ page: val });
    }
  } else {
    if (store.info != "") {
      store.searchInfo(val);
    } else {
      store.initQuoteList({ page: val }, true);
    }
  }
  currentPage1.value = val;
};

// 用户点击了收起
const packUp = () => {
  isShowNav.value = !isShowNav.value;
};

//点击了搜索
const search = (keyWords) => {
  resethot(); //清空热词搜索关键字
  store.params.info = keyWords.value; // tzc
  if (!route.path.includes("search")) {
    // 没在搜索页
    // 跳转到 最新报价，然后进行查询
    router.push({
      name: `search-offer`,
      params: {
        isChnageBreadcrumb: `${keyWords.value}的搜索结果`,
        keyWords: keyWords.value,
      },
    });
  } else {
    // 在搜索页 直接搜索就可以了
    searchHnadler();
  }
  breadcrumbStore.setMatched(keyWords.value); // 设置面包屑
};

//当用户输入了金额并且点击了确定时
const changePrice = (priceArr) => {
  //通过价格进行查询
  if (route.path.includes("search")) {
    searchHnadler();
  } else {
    router.push({
      name: `search-offer`,
    });
  }
};

// 用户点击more后触发的时间
const moreHandler = (row) => {
  store.clickMore({ company_id: row.id, limit: 4, target: "offer" });
};

// 用户点击收起时触发
const puckHandler = (row) => {
  store.clickPuck(row, "offer");
};

// 子组件dom
const specDom = ref(null);
const textureDom = ref(null);
const nameDom = ref(null);

// 材质和规格 的setAll
const PsetAll = () => {
  specDom.value.setAll();
  textureDom.value.setAll();
};

// 当点击其他选项的时候进行重置到全部，同时把保存的查询参数也要干掉
const setAllHandler = (_) => {
  PsetAll();
  nameDom.value.setAll();
  store.clearParams();
};

// 如果是关键热词进行搜索的话 那么就会清空 品名 规格 参数 这些搜索参数 回归到全部
watchEffect(() => {
  if (store.hotwords) {
    nextTick(() => {
      setAllHandler();
    });
  }
});

return (_ctx, _cache) => {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(SearchGoods), {
      onPackUp: packUp,
      onSearch: search,
      onPrice: changePrice,
      isShowNav: isShowNav.value,
      onSetAll: setAllHandler,
      onChangeCity: changCity,
      onChangeType: changeType,
      onChangeQy: changeQy,
      ref_key: "searchGoods",
      ref: searchGoods
    }, null, 8, ["isShowNav"]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(navViewsVue, {
            searchList: { title: '品名', children: _unref(storeClassIfy).nameArr },
            onClickName: clickHandler,
            onAll: clickAll,
            ref_key: "nameDom",
            ref: nameDom
          }, null, 8, ["searchList"]),
          _createVNode(navViewsVue, {
            searchList: { title: '规格', children: _unref(storeClassIfy).specList },
            onClickOthrer: clickOthrer1,
            onAll: clickAll,
            ref_key: "specDom",
            ref: specDom
          }, null, 8, ["searchList"]),
          _createVNode(navViewsVue, {
            searchList: { title: '材质', children: _unref(storeClassIfy).textureList },
            onClickOthrer: clickOthrer2,
            onAll: clickAll,
            ref_key: "textureDom",
            ref: textureDom
          }, null, 8, ["searchList"])
        ], 512), [
          [_vShow, isShowNav.value]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ShowNewOffer, {
        onSearch: searchHandle,
        search: searchWords.value,
        newOfferTable: _unref(store).quoteList,
        onMoreHandler: moreHandler,
        onPuck: puckHandler
      }, null, 8, ["search", "newOfferTable"]),
      (_unref(store).total > 20)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_pagination, {
              currentPage: currentPage1.value,
              "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => ((currentPage1).value = $event)),
              "page-size": 20,
              size: "small",
              background: "",
              layout: " prev, pager,next,total,jumper",
              total: _unref(store).total,
              onCurrentChange: handleCurrentChange
            }, null, 8, ["currentPage", "total"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

}